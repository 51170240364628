import {EnumType, JacksonType} from "@sonner/jackson-service-v2";
import {TipoSolicitacaoEnum} from "../../../enums/tipo.solicitacao.enum";

@JacksonType("JustificativaIndeferimentoFilter")
export class JustificativaIndeferimentoFilter {

  id: number;

  idMunicipio: number;

  @EnumType(TipoSolicitacaoEnum)
  tipoSolicitacao: TipoSolicitacaoEnum;
}
