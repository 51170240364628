import {JacksonType} from "@sonner/jackson-service-v2";

@JacksonType("VinculoAtividadeFilter")
export class VinculoAtividadeFilter {

  id: number;

  idAtividade: number;

  idAtividadeCTISS: number;

  idPessoa: number;
}
